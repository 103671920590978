body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
* CSS file to hold styles that apply across the application
*/

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  background-color: black;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
  display: grid;
  place-items: center normal;
  /* align-content: center; */
}

section.home {
  position: relative;
  width: 100vw;
  display: grid;
  min-height: 100vh;
  background: grey;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  /* height: 100%; */
  /* left: 50%; */
  object-fit: cover;
  /* pointer-events: none; */
  /* position: absolute; */
  /* top: 0; */
  /* transform: translate(-50%, 0); */
  width: 100%;
  /* z-index: -1; */
}

h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: 0.8;
  margin: 0;
}

h2 {
  /* font-size: 3rem; */
  font-weight: 600;
  margin: 0;
  padding-top: 1rem;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
  color: white;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
}

.box {
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

/* add a slide in animation when the section is scrolled to*/
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 2s;
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.canvas {
  background-color: "black";
  /* increase height of canvas */
  height: 100vh;
}

.rowC {
  display: flex;
  flex-direction: row;
}

.wrapper {
  position: relative;
  justify-content: center;
  align-self: center;
  height: 72vh;
}

.background {
  width: 30%;
  height: 100vh;
  background-size: contain;
  /* background-position: center, center; */
}

/* Photogallery */

#image-track {
  display: flex;
  gap: 4vmin;
  position: relative;
  left: 50%;
  top: 50%;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -50%);
  user-select: none; /* -- Prevent image highlighting -- */
}

#image-track > .image {
  width: 40vmin;
  height: 60vmin;
  object-fit: cover;
  object-position: 100% center;
}

/* Language Toggle */

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  /* transition: margin 0.2s; */
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #78c5ef;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner:before {
  content: "KR";
  padding-left: 10px;
  background-color: #78c5ef;
  color: #fff;
}

.toggle-switch-inner:after {
  content: "EN";
  padding-right: 10px;
  background-color: #78c5ef;
  color: #fff;
  text-align: right;
}

.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border-radius: 20px;
  transition: margin 0.2s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
